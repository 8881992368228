import { Modal, Table, Spin } from 'antd'
import Analysis from '../analysis'


const AnalysisModal = ({ 
  visible, 
  setVisible, 
  players, 
  onExposureChange, 
  settings, 
  teamStacks, 
  positionCounts,
  showdown,
  site,
  counter,
  season,
  sport,
  permissions
}) => {

    return (
        <Modal
            title={`Player Table Analysis`}
            open={visible}
            onOk={()=>{
                setVisible(false)
            }}
            onCancel={()=>{
                setVisible(false)
            }}
            style={{ top: '5%'}}
            width={'95%'}
            destroyOnClose={true}
            footer={null}
        >
            <Analysis
              players={players}
              onExposureChange={onExposureChange}
              settings={settings}
              teamStacks={teamStacks}
              permissions={permissions}
              positionCounts={positionCounts}
              showdown={showdown}
              site={site}
              counter={counter}
              season={season}
              sport={sport}
            />
        </Modal>
    )
}

export default AnalysisModal