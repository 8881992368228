export const reorderNFLRuns = (lineups) => {
  const lus = []
  lineups.forEach((lu, i) => {
    const _lu = []
    lu.rawTable.forEach(player => {
      _lu.push({
        ...player,
        Run: i + 1
      })
    })
    lus.push({
      rawTable: _lu,
      lineupHash: lu.lineupHash
    })
  })

  return lus
}

export const reorderRawNFLRuns = (lineups) => {
  return lineups.map((lu, i) => {
    return {
      ...lu,
      Run: Math.floor(i / 9) + 1
    }
  })
}

export const reorderNBARuns = (lineups) => {
  const lus = []
  lineups.forEach((lu, i) => {
    const _lu = []
    lu.rawTable.forEach(player => {
      _lu.push({
        ...player,
        Run: i + 1
      })
    })
    lus.push({
      rawTable: _lu,
      lineupHash: lu.lineupHash
    })
  })

  return lus
}


export const reorderMLBRuns = (lineups) => {
  const lus = []
  lineups.forEach((lu, i) => {
    const _lu = []
    lu.rawTable.forEach(player => {
      _lu.push({
        ...player,
        Run: i + 1
      })
    })
    lus.push({
      rawTable: _lu,
      lineupHash: lu.lineupHash
    })
  })

  return lus
}

export const reorderPositionlessRuns = (lineups) => {
  const lus = []
  lineups.forEach((lu, i) => {
    const _lu = []
    lu.rawTable.forEach(player => {
      _lu.push({
        ...player,
        Run: i + 1
      })
    })
    lus.push({
      rawTable: _lu,
      lineupHash: lu.lineupHash
    })
  })

  return lus
}