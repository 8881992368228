import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import Initializer from './initializer'
import LineupGenerator from '../containers/lineup-generator'

import './app.css'

const App = ({ store }) => (
  <Provider store={store}>
    <Initializer>
      <div id="router-root">
          <div id="scroll-root">
            <LineupGenerator />
          </div>
      </div>
    </Initializer>
  </Provider>
)

App.propTypes = {
  // State
  store: PropTypes.shape({}).isRequired,

  // Testing
  testElement: PropTypes.element
}

App.defaultProps = {
  // Testing
  testElement: null
}

export default App
