import React from 'react'
import { Table, Tooltip } from 'antd'
import styled from 'styled-components'
import {
  LockOutlined,
} from '@ant-design/icons'
import { CPTDisplay } from '../../../../../../utils/showdown'

const ROW_COLS_KEY = 'row-cols'

const RightDividerRow = styled.div`
  border-right: 1px solid black;
`

const classicCols = [
  {
    'title': 'Position',
    'dataIndex': ROW_COLS_KEY,
    'key': ROW_COLS_KEY
  },
  {
    'title': 'P',
    'dataIndex': 'p',
    'key': 'p'
  },
  {
    'title': 'C',
    'dataIndex': 'c',
    'key': 'c'
  },
  {
    'title': '1B',
    'dataIndex': '1b',
    'key': '1b'
  },
  {
    'title': '2B',
    'dataIndex': '2b',
    'key': '2b'
  },
  {
    'title': 'SS',
    'dataIndex': 'ss',
    'key': 'ss',
  },
  {
    'title': '3B',
    'dataIndex': '3b',
    'key': '3b',
  },
  {
    'title': 'OF',
    'dataIndex': 'of',
    'key': 'of',
  },
  {
    'title': 'All',
    'dataIndex': 'all',
    'key': 'all'
  }
]
const showdownCols = (site) => [
  {
    'title': 'Position',
    'dataIndex': ROW_COLS_KEY,
    'key': ROW_COLS_KEY
  },
  {
    'title': CPTDisplay[site],
    'dataIndex': 'cpt',
    'key': 'cpt'
  },
  {
    'title': 'FLEX',
    'dataIndex': 'flex',
    'key': 'flex'
  },
  {
    'title': 'All',
    'dataIndex': 'all',
    'key': 'all'
  }
]

const rowCols = [
  "Number in Player Pool",
  "Min Exp.",
  "Max Exp."
]

const StyledTable = styled(Table)`
  .divide {
    border-right: 1px solid black !important;
  }

  .filled {
    background: #1890ff;
  }
`

const InputTooHigh = styled.span`
  &.error {
    color: #ff5252;
  }
`

const Lock = () => (
  <Tooltip title="Upgrade for this feature">
    <LockOutlined />
  </Tooltip>
)

const lowerRange = 'Pct_25'
const upperRange = 'Pct_75'

/**
  POSITIONAL BREAKDOWN TABLE

  pass players
*/
const PositionBreakdownTable = ({ players, permissioned, positionCounts, showdown, site }) => {
  const _data = getFormattedTableFigures(players, permissioned, positionCounts, showdown)
  const columns = showdown ? showdownCols(site) : classicCols

  return (
    <StyledTable
      bordered
      columns={columns}
      dataSource={_data || []} pagination={false}
      size={'small'}
      scroll={{x: '150px'}}
      rowKey ={(_row, i) => {
        return `pre-analysis-pos-breakdown-${i}`
      }}
    />
  )
}

const getFormattedTableFigures = (players, permissioned, positionCounts, showdown) => {
  let exposures = initExposuresObject(showdown)

  players.forEach(_player => {
    if (Number(_player["MaxExp"]) > 0) {
      // Add to position
      if (showdown) {
        exposures[_player.RosterPosition].count++
        exposures[_player.RosterPosition].minExp+=Number(_player["MinExp"])
        exposures[_player.RosterPosition].maxExp+=Number(_player["MaxExp"])
      } else {
        _player.Positions.forEach(pos => {
          exposures[pos].count++
          exposures[pos].minExp+=Number(_player["MinExp"])
          exposures[pos].maxExp+=Number(_player["MaxExp"])
        })
      }

      // Add to All
      exposures["ALL"].count++
      exposures["ALL"].minExp+=Number(_player["MinExp"])
      exposures["ALL"].maxExp+=Number(_player["MaxExp"])
    }
  })

  let cols

  if (showdown) {
    cols = getShowdownCols(exposures, players, permissioned, positionCounts, showdown)
  } else {
    cols = getClassicCols(exposures, players, permissioned, positionCounts, showdown)
  }

  return cols
}

const initExposuresObject = (showdown) => {
  if (!showdown) {
    return {
        'P': {
          "count": 0,
          "minExp": 0,
          "maxExp": 0
        },
        'C': {
          "count": 0,
          "minExp": 0,
          "maxExp": 0
        },
      '1B': {
        "count": 0,
        "minExp": 0,
        "maxExp": 0
      },
      '2B': {
        "count": 0,
        "minExp": 0,
        "maxExp": 0
      },
      'SS': {
        "count": 0,
        "minExp": 0,
        "maxExp": 0
      },
      '3B': {
        "count": 0,
        "minExp": 0,
        "maxExp": 0
      },
      'OF': {
        "count": 0,
        "minExp": 0,
        "maxExp": 0
      },
      'ALL': {
        "count": 0,
        "minExp": 0,
        "maxExp": 0
      }
    }
  } else {
    return {
      'CPT': {
        "count": 0,
        "minExp": 0,
        "maxExp": 0
      },
      'FLEX': {
        "count": 0,
        "minExp": 0,
        "maxExp": 0
      },
      'ALL': {
        "count": 0,
        "minExp": 0,
        "maxExp": 0
      }
    }
  }
}

const getShowdownCols = (exposures, players, permissioned, positionCounts, showdown) => {
  const cols = []
  // Add counts
  const counts = {
    [ROW_COLS_KEY]: rowCols[0],
    'cpt': exposures['CPT']['count'],
    'flex': exposures['FLEX']['count'],
    'all': exposures['ALL']['count'],
  }
  cols.push(counts)
  // Add min exp
  cols.push({
    [ROW_COLS_KEY]: rowCols[2],
    'cpt': exposures['CPT']['minExp'].toFixed(0),
    'flex': exposures['FLEX']['minExp'].toFixed(0),
    'all': exposures['ALL']['minExp'].toFixed(0)
  })
  // Add max exp
  cols.push({
    [ROW_COLS_KEY]: rowCols[3],
    'cpt': exposures['CPT']['maxExp'].toFixed(0),
    'flex': exposures['FLEX']['maxExp'].toFixed(0),
    'all': exposures['ALL']['maxExp'].toFixed(0)
  })

  return cols
}

const getClassicCols = (exposures, players, permissioned, positionCounts, showdown) => {
  const cols = []
  // Add counts
  const counts = {
    [ROW_COLS_KEY]: rowCols[0],
    'p': exposures['P']['count'],
    'c': exposures['C']['count'],
    '1b': exposures['1B']['count'],
    '2b': exposures['2B']['count'],
    'ss': exposures['SS']['count'],
    '3b': exposures['3B']['count'],
    'of': exposures['OF']['count'],
    'all': exposures['ALL']['count'],
  }
  cols.push(counts)
  // Add empty row
  cols.push({
    [ROW_COLS_KEY]: '',
    'p': '',
    'c': '',
    '1b': '',
    '2b': '',
    'ss': '',
    '3b': '',
    'of': '',
    'all': '',
  })
  // Add min exp
  cols.push({
    [ROW_COLS_KEY]: rowCols[1],
    'p': exposures['P']['minExp'].toFixed(0),
    'c': exposures['C']['minExp'].toFixed(0),
    '1b': exposures['1B']['minExp'].toFixed(0),
    '2b': exposures['2B']['minExp'].toFixed(0),
    'ss': exposures['SS']['minExp'].toFixed(0),
    '3b': exposures['3B']['minExp'].toFixed(0),
    'of': exposures['OF']['minExp'].toFixed(0),
  })
  // Add max exp
  cols.push({
    [ROW_COLS_KEY]: rowCols[2],
    'p': exposures['P']['maxExp'].toFixed(0),
    'c': exposures['C']['maxExp'].toFixed(0),
    '1b': exposures['1B']['maxExp'].toFixed(0),
    '2b': exposures['2B']['maxExp'].toFixed(0),
    'ss': exposures['SS']['maxExp'].toFixed(0),
    '3b': exposures['3B']['maxExp'].toFixed(0),
    'of': exposures['OF']['maxExp'].toFixed(0),
  })

  return cols
}

export default PositionBreakdownTable
