import React from 'react'
import styled from 'styled-components'

import NFLWeekPicker from './nfl-week-picker'
import MLBDatePicker from './mlb-date-picker'
import { getSportImg } from '../utils/team-images-loader'

import SlatesSelect from './slates-select'
import WeekLongDatePicker from './week-long-date-picker'

const StyledSection = styled.div`
  display: flex;
  align-items: center;
`

const sportToLogoMap = {
  'nfl': (
    getSportImg('nfl')
  ),
  'nba': (
    getSportImg('nba')
  ),
  'mlb': (
    getSportImg('mlb')
  ),
  'pga': (
    getSportImg('pga')
  ),
}

export const SectionHeaderTitle = ({sport, site, counter, season, changeDateInfo, updateSite, updateSlate, slate}) => {
  let body = counter

  switch(sport) {
    case 'nfl':
      body = (<NFLWeekPicker selectedWeek={counter} selectedSeason={season} changeDateInfo={changeDateInfo} site={site} updateSite={updateSite} />)
      break
    case 'mlb':
      body = (<MLBDatePicker selectedDate={counter} selectedSeason={season} changeDateInfo={changeDateInfo} site={site} updateSite={updateSite} />)
      break
    case 'nba':
      body = (<MLBDatePicker selectedDate={counter} selectedSeason={season} changeDateInfo={changeDateInfo} site={site} updateSite={updateSite} />)
      break
    case 'pga':
      body = (<MLBDatePicker selectedDate={counter} selectedSeason={season} changeDateInfo={changeDateInfo} site={site} updateSite={updateSite} />)
      break
    case 'mma':
      body = (<WeekLongDatePicker selectedDate={counter} selectedSeason={season} changeDateInfo={changeDateInfo} site={site} updateSite={updateSite} />)
      break
    case 'nascar':
      body = (<WeekLongDatePicker selectedDate={counter} selectedSeason={season} changeDateInfo={changeDateInfo} site={site} updateSite={updateSite} />)
      break
    default:
      body = (<MLBDatePicker selectedDate={counter} selectedSeason={season} changeDateInfo={changeDateInfo} site={site} updateSite={updateSite} />)
      break
  }

  return (
    <StyledSection>
      {sportToLogoMap[sport]}
      {
        body
      }  
      <SlatesSelect
          updateSlate={updateSlate}
          slate={slate}
          site={site}
          sport={sport}
          counter={counter}
          season={season}
        />
    </StyledSection>
  )
}