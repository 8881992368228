const simpleHash = (_hashString) => {
  let hash = 0;
  if (_hashString.length === 0) {
      return hash
  }
  for (var i = 0; i < _hashString.length; i++) {
      const char = _hashString.charCodeAt(i);
      hash = ((hash<<5)-hash)+char;
      hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

export default simpleHash

export const lineupHash = (normalizedLineup, positions) => {
  let _hashableString = ''
  positions.forEach(_pos => {
    _hashableString += normalizedLineup[_pos].Name
    _hashableString += normalizedLineup[_pos].ProjOwn
  })
  return simpleHash(_hashableString)
}
