import { Select } from 'antd'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

const SlatesContainer = styled(Select)`
`

import * as lineupActions from '../actions/lineup'

const BannedClassicSlates = ["Superflex", "Mon-Thu", "MNF-TNF", "Touchdowns", "Rush and Rec"]
const BannedShowdownSlates = []

const SlatesSelect = ({slates, slate, site, sport, counter, season, fetchSlates, updateSlate}) => {
  useEffect(() => {
    if (counter && season) {
      fetchSlates({site: site, counter: counter, season: season, sport: sport })
    }
  }, [counter, season, site])

  const filterSlates = (_slates) => {
    const _filteredClassicSlates = []
    const _filteredShowdownSlates = []

    _slates.classic.forEach(s => {
      let match = false
      BannedClassicSlates.forEach(t => {
        if (s.toLowerCase().includes(t.toLowerCase())) match = true
      })

      if (!match) _filteredClassicSlates.push(s)
    })

    _slates.showdown.forEach(s => {
      let match = false
      BannedShowdownSlates.forEach(t => {
        if (s.toLowerCase().includes(t.toLowerCase())) match = true
      })

      if (!match) _filteredShowdownSlates.push(s)
    })

    return {
      'showdown': _filteredShowdownSlates,
      'classic': _filteredClassicSlates
    }
  }

  let _slates = {
    "classic": ["Main"]
  }
  if (slates.data)
    _slates = filterSlates(slates.data)

  return (
      <SlatesContainer style={{width: '200px'}} onChange={(slate) => updateSlate(slate, season, counter)} value={slate}>
        {
          _slates &&
          _slates.classic &&
          _slates.classic.map(_slate => {
            let _displayName = _slate
            
            return (
              <Select.Option key={_slate} value={`classic|${_slate}`}>{_displayName}</Select.Option>
            )
          })
        }
        {
          _slates &&
          _slates.showdown &&
          _slates.showdown.map(_slate => {
            let _displayName = _slate

            _displayName = _displayName.replace("Captain Mode", "")

            return (
              <Select.Option key={_slate} value={`showdown|${_slate}`}>{_displayName}</Select.Option>
            )
          })
        }
      </SlatesContainer>
  )
}

export default connect(
  state => ({
    slates: state.lineup.slates
  }),
  {
    fetchSlates: lineupActions.fetchSlates,
  }
)(SlatesSelect)
