import React from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'

const StyledTimestamp = styled.div`
  color: #9a9a9a;
  float: right;
  font-size: 12px;
  margin: 5px;
`
const StyledSpan = styled.span`
  color: #1890ff;
  margin-right: 10px;
  cursor: pointer;
`

function convertDateForIos(date) {
  var arr = date.split(/[- :]/);
  date = new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]);
  return date;
}

const LastUpdated = ({ timestamp, refresh, shortened=false }) => {
  if (!timestamp) return ''
  // All timestamps are in GMT
  // const _localDate = new Date(`${timestamp} GMT`)
  // let hours = _localDate.getHours()
  // let minutes = _localDate.getMinutes()
  // const ampm = hours >= 12 ? 'pm' : 'am'
  // hours = hours % 12
  // hours = hours ? hours : 12 // the hour '0' should be '12'
  // minutes = minutes < 10 ? '0'+minutes : minutes
  // let strTime
  // if (!shortened) {
  //   strTime = `${_localDate.getMonth() + 1}-${_localDate.getDate()}-${_localDate.getFullYear()} ${hours}:${minutes} ${ampm}`
  // } else {
  //   strTime = `${hours}:${minutes} ${ampm}`
  // }
  const time = dayjs(timestamp)  
  const strTime = time.format("MM-DD-YYYY h:mm a")
  
  return (
    <StyledTimestamp>
      {
        refresh ? (
          <StyledSpan onClick={refresh} >Refresh</StyledSpan>
        ) : ''
      }
      Last updated: {strTime}
    </StyledTimestamp>
  )
}

export default LastUpdated
