import { Card, Col, Row, Alert, Spin } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'

import PositionBreakdownTableNFL from './Analysis/nfl/position-breakdown'
import TopOwnedPlayersNFL from './Analysis/nfl/player-breakdown'
import MatchupBreakdownTableNFL from './Analysis/nfl/matchup-breakdown'
import OwnershipBreakdownNFL from './Analysis/nfl/ownership-breakdown'

import PositionBreakdownTableMLB from './Analysis/mlb/position-breakdown'
import TopOwnedPlayersMLB from './Analysis/mlb/player-breakdown'
import MatchupBreakdownTableMLB from './Analysis/mlb/matchup-breakdown'
import OwnershipBreakdownMLB from './Analysis/mlb/ownership-breakdown'

import PositionBreakdownTableNBA from './Analysis/nba/position-breakdown'
import TopOwnedPlayersNBA from './Analysis/nba/player-breakdown'
import MatchupBreakdownTableNBA from './Analysis/nba/matchup-breakdown'
import OwnershipBreakdownNBA from './Analysis/nba/ownership-breakdown'


import Overview from './Analysis/overview'
import {
  validateBuild,
  validateSettings,
  validateTeamStacks
} from './Analysis/utils/validate'

const PlayersAnalysisContainer = styled.div`
  margin-top: 30px;
`
const StyledAlert = styled(Alert)`
  margin-bottom: 10px;
  &:hover {
    font-weight: 600;
  }
`

const PlayersAnalysis = ({ players, teamStacks, settings, onExposureChange, permissions, positionCounts, showdown, site, sport }) => {
  // Show spinning if still loading
  if (sport === 'nfl' && positionCounts && Object.keys(positionCounts).length === 0 && positionCounts.constructor === Object) {
    return (
      <PlayersAnalysisContainer>
        <Card style={{textAlign: 'center'}}>
          <Spin />
        </Card>
      </PlayersAnalysisContainer>
    )
  }

  let MatchupBreakdown
  let PositionalBreakdown
  let TopOwnedPlayers
  let OwnershipBreakdown
  switch (sport) {
    case 'nfl':
      MatchupBreakdown = MatchupBreakdownTableNFL
      PositionalBreakdown = PositionBreakdownTableNFL
      TopOwnedPlayers = TopOwnedPlayersNFL
      OwnershipBreakdown = OwnershipBreakdownNFL
      break
    case 'mlb':
      MatchupBreakdown = MatchupBreakdownTableMLB
      PositionalBreakdown = PositionBreakdownTableMLB
      TopOwnedPlayers = TopOwnedPlayersMLB
      OwnershipBreakdown = OwnershipBreakdownMLB
      break
    case 'nba':
      MatchupBreakdown = MatchupBreakdownTableNBA
      PositionalBreakdown = PositionBreakdownTableNBA
      TopOwnedPlayers = TopOwnedPlayersNBA
      OwnershipBreakdown = OwnershipBreakdownNBA
      break
    default:
      MatchupBreakdown = Card
      PositionalBreakdown = Card
      TopOwnedPlayers = Card
      OwnershipBreakdown = Card
  }
  

  const [ hasErrors, setHasErrors ] = useState(false)
  let warnings = []
  if (!showdown)
    warnings = generateWarnings(players, teamStacks, settings, hasErrors, setHasErrors)

  return (
    <PlayersAnalysisContainer>
      <Overview players={players} warnings={warnings} permissioned={true} />
      {
        hasErrors ? (
          <Alert message={'The current build has issues that may cause unexpected results or could cause the build to fail in some circumstances. See Warnings for details.'} type="error" style={{textAlign: 'center'}} />
        ) : ''
      }
      <Card>
        <Row>
          <Col lg={10}>
            <h3>Positional Breakdown</h3>
            <div style={{margin: '12px 0px'}} />
            <PositionalBreakdown players={players} permissioned={true} positionCounts={positionCounts} showdown={showdown} site={site} />
          </Col>
          <Col lg={10} offset={2}>
            <h3>Ownership Breakdown</h3>
            <div style={{margin: '12px 0px'}} />
            <OwnershipBreakdown players={players} permissioned={true} />
          </Col>
        </Row>
      </Card>
      <Card>
        <Row>
          <Col lg={10}>
            <h3>Player Breakdown</h3>
            <TopOwnedPlayers players={players} onExposureChange={onExposureChange} permissioned={true} showdown={showdown} site={site} sport={sport}/>
          </Col>
          <Col lg={10} offset={2}>
            <h3>Team Breakdown</h3>
            <div style={{marginTop: '8px'}} />
            <MatchupBreakdown players={players} onExposureChange={onExposureChange} settings={settings} permissioned={true} sport={sport} />
          </Col>
        </Row>
      </Card>
    </PlayersAnalysisContainer>
  )
}

const generateWarnings = (players, teamStacks, settings, hasErrors, setHasErrors) => {
  let warnings = []
  // validate build for errors
  try{
    const [ valid, errors ] = validateBuild(players)
    if (!valid) {
      warnings = warnings.concat(errors)
      if (!hasErrors)
        setHasErrors(true)
    }
    const [ settingsValid, settingsErrors ] = validateSettings(players, settings)
    if (!settingsValid) {
      warnings = warnings.concat(settingsErrors)
      if (!hasErrors)
        setHasErrors(true)
    }

    const [ teamStacksValid, teamStacksErrors ] = validateTeamStacks(players, settings, teamStacks)
    if (!teamStacksValid) {
      warnings = warnings.concat(teamStacksErrors)
      if (!hasErrors)
        setHasErrors(true)
    }
  } catch(e) {
    // don't need to crash the page if warnings don't generate properly
    console.error("Unable to generate warnings")
  }

  return warnings
}

export default PlayersAnalysis
