import SaveLineupStar from '../components/save-lineup-star'

export const POSITIONLESS_SPORTS = ['pga']

export const POSITIONLESS_LU_HEADING = {
  'pga': {
    'dk': 'G',
    'fd': 'G'
  },
  'mma': {
    'dk': 'F'
  },
  'nascar': {
    'dk': 'D'
  }
}

export const GET_POSITIONLESS_ROSTER_POSITIONS = (luSize, sport, site) => {
  const headingPrefix = POSITIONLESS_LU_HEADING[sport][site]

  let rosterPos = []
  for (let i=0; i<luSize; i++) {
    rosterPos.push(`${headingPrefix}${i + 1}`)
  }

  return rosterPos
}

export const GET_POSITIONLESS_COLS = (luSize, sport, site, slate, showSaved, counter, season) => {
  const rosterPositions = GET_POSITIONLESS_ROSTER_POSITIONS(luSize, sport, site)
  let cols = [
    {
      title: '',
      width: '50px',
      render: (_, row) => {
        return (
          <SaveLineupStar
            lineup={row}
            site={site}
            slate={slate}
            saved={showSaved}
            sport={sport}
            positions={rosterPositions}
            counter={counter}
            season={season}
          />
        )
      }
    }
  ]
  
  for (let i=0; i<luSize; i++) {
    const pos = rosterPositions[i]
    cols.push(
      {
        title: pos,
        dataIndex: pos,
        key: pos,
        render: (player) => {return player.Name}
      },
    )
  }

  return cols
}