const lineupSize = {
  'nfl': {
    'dk': {
      'classic': 9,
      'showdown': 6
    },
    'fd': {
      'classic': 9,
      'showdown': 5
    },
    'ya': {
      'classic': 9,
      'showdown': 5
    },
  },
  'nba': {
    'dk': {
      'classic': 8
    },
    'fd': {
      'classic': 9
    }
  },
  'mlb': {
    'dk': {
      'classic': 10
    },
    'fd': {
      'classic': 9
    }
  },
  'pga': {
    'dk': {
      'classic': 6
    },
    'fd': {
      'classic': 6
    },
  },
  'mma': {
    'dk': {
      'classic': 6
    },
    'fd': {
      'classic': 6
    },
  },
  'nascar': {
    'dk': {
      'classic': 6
    },
    'fd': {
      'classic': 6
    },
  }
}

export const getLineupSize = (site, contestType='classic', sport='nfl') => {
  return lineupSize[sport][site][contestType]
}

export const formatNameIDForUpload = (site, player, showdown=false) => {
  switch (site) {
    case 'dk':
      // remove Id for showdown ids
      if (showdown)
        return `${player.Name} (${player.Id.split("|")[0]})`
      else
        return `${player.Name} (${player.Id})`
    case 'fd':
      // remove Id for showdown ids
      if (showdown)
        return `${player.Id.split("|")[0]}:${player.Name}`
      else
        return `${player.Id}:${player.Name}`
    case 'ya':
      // remove Id for showdown ids
      if (showdown)
        return `${player.Id.split("|")[0]} - ${player.Name}`
      else
        return `${player.Id} - ${player.Name}`
    default:
      return `${player.Name} (${player.Id})`
  }
}
