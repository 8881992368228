export const positionAllowanceClassicNFL = {
  'dk': {
    'qb': ['QB'],
    'rb': ['RB1', 'RB2', 'FLEX'],
    'wr': ['WR1', 'WR2', 'WR3', 'FLEX'],
    'te': ['TE', 'FLEX'],
    'dst': ['DST'],
    'def': ['DEF'],
    'k': ['K'],
  },
  'fd': {
    'qb': ['QB'],
    'rb': ['RB1', 'RB2', 'FLEX'],
    'wr': ['WR1', 'WR2', 'WR3', 'FLEX'],
    'te': ['TE', 'FLEX'],
    'dst': ['DST'],
    'def': ['DEF'],
    'k': ['K'],
  },
  'ya': {
    'qb': ['QB'],
    'rb': ['RB1', 'RB2', 'FLEX'],
    'wr': ['WR1', 'WR2', 'WR3', 'FLEX'],
    'te': ['TE', 'FLEX'],
    'dst': ['DST'],
    'def': ['DEF'],
    'k': ['K'],
  }
}
export const rosterPositionShowdownNFL = {
  'CPT': ['CPT'],
  'FLEX': ['FLEX1', 'FLEX2', 'FLEX3', 'FLEX4', 'FLEX5', 'FLEX6', 'FLEX7', 'FLEX8', 'FLEX9']
}

export const positionAllowanceClassicMLB = {
  'dk': {
    'p': ['p1', 'p2'],
    'c': ['c'],
    '1b': ['1b'],
    '2b': ['2b'],
    'ss': ['ss'],
    '3b': ['3b'],
    'of': ['of1', 'of2', 'of3'],
  },
  'fd': {
    'p': ['p'],
    'c': ['c/1b', 'util'],
    '1b': ['c/1b', 'util'],
    '2b': ['2b', 'util'],
    'ss': ['ss', 'util'],
    '3b': ['3b', 'util'],
    'of': ['of1', 'of2', 'of3', 'util'],
  }
}
export const rosterPositionShowdownMLB = {
  'CPT': ['CPT'],
  'FLEX': ['FLEX1', 'FLEX2', 'FLEX3', 'FLEX4', 'FLEX5', 'FLEX6', 'FLEX7', 'FLEX8', 'FLEX9']
}
export const positionAllowanceClassicNBA = {
  'dk': {
    'pg': ['pg1', 'util', 'g'],
    'sg': ['sg1', 'util', 'g'],
    'sf': ['sf1', 'util', 'f'],
    'pf': ['pf1', 'util', 'f'],
    'c': ['c', 'util']
  },
  'fd': {
    'pg': ['pg1', 'pg2'],
    'sg': ['sg1', 'sg2'],
    'sf': ['sf1', 'sf2'],
    'pf': ['pf1', 'pf2'],
    'c': ['c']
  }
}
export const rosterPositionShowdownNBA = {
  'CPT': ['CPT'],
  'FLEX': ['FLEX1', 'FLEX2', 'FLEX3', 'FLEX4', 'FLEX5', 'FLEX6', 'FLEX7', 'FLEX8', 'FLEX9']
}