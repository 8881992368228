import { getLineupSize } from './contests'
import { GET_POSITIONLESS_ROSTER_POSITIONS, POSITIONLESS_LU_HEADING } from '../constants/positionless'


export const positions = ['QB', 'RB1', 'RB2', 'WR1', 'WR2', 'WR3', 'TE', 'FLEX', 'DST']

export const getPositionsClassic = (site, sport) => {
  if (sport === 'nfl')
    return positions
  else if (sport === 'nba') {
    if (site === 'dk')
      return ['pg1', 'sg1', 'sf1', 'pf1', 'c', 'g', 'f', 'util']
    else if (site === 'fd')
      return ['pg1', 'pg2', 'sg1', 'sg2', 'sf1', 'sf2', 'pf1', 'pf2', 'c']
  } else if (sport === 'mlb') {
    if (site === 'dk') {
      return ['p1', 'p2', 'c', '1b', '2b', '3b', 'ss', 'of1', 'of2', 'of3']
    } else if (site === 'fd') {
      return ['p', 'c/1b', '2b', 'ss', '3b', 'of1', 'of2', 'of3', 'util']
    }
  } else if (sport === 'pga') {
    return ['g1', 'g2', 'g3', 'g4', 'g5', 'g6']
  } else if (sport === 'mma') {
    return ['f1', 'f2', 'f3', 'f4', 'f5', 'f6']
  } else if (sport === 'nascar') {
    return ['d1', 'd2', 'd3', 'd4', 'd5', 'd6']
  }

  throw Error(`no positions for ${site} : ${sport}`)
}

const NFL_HEADINGS = {
  'dk': ['QB','RB','RB','WR','WR','WR','TE','FLEX','DST'],
  'fd': ['QB','RB','RB','WR','WR','WR','TE','FLEX','DEF'],
  'ya': ['QB','RB1','RB2','WR1','WR2','WR3','TE','FLEX','DST']
}
export const getCSVPositionsClassic = (site, sport) => {
  if (sport === 'nfl')
    return NFL_HEADINGS[site]
  else if (sport === 'nba') {
    if (site === 'dk')
      return ['pg1', 'sg1', 'sf1', 'pf1', 'c', 'g', 'f', 'util']
    else if (site === 'fd')
      return ['pg1', 'pg2', 'sg1', 'sg2', 'sf1', 'sf2', 'pf1', 'pf2', 'c']
  } else if (sport === 'mlb') {
    if (site === 'dk') {
      return ['p', 'p', 'c', '1b', '2b', '3b', 'ss', 'of', 'of', 'of']
    } else if (site === 'fd') {
      return ['p', 'c/1b', '2b', 'ss', '3b', 'of1', 'of2', 'of3', 'util']
    }
  }

  throw Error(`no positions for ${site} : ${sport}`)
}

export const getPositionsShowdown = (site, sport) => {
  const positions = ['CPT']
  const lineupSize = getLineupSize(site, 'showdown', sport)
  for (let i = 1; i < lineupSize; i++) {
    positions.push(`FLEX${i}`)
  }

  return positions
}

export const normalizeLineup = (lu, site="dk", showdown=false, sport='nfl') => {
  if (!lu) return {}
  const _normalizedLU = {}
  const lineupSize = getLineupSize(site, showdown ? 'showdown' : 'classic', sport)
  const _positions = getPositionsClassic(site, sport)

  if (showdown) {
    const numFlex = lineupSize - 1
    for (let i = 0; i < lineupSize; i++) {
      if (i === 0)
        _normalizedLU['CPT'] = lu[i]
      else
        _normalizedLU[`FLEX${i}`] = lu[i]
    }
  } else {
    for (let i = 0; i < lineupSize; i++) {
      _normalizedLU[_positions[i]] = lu[i]
    }
  }

  return _normalizedLU
}

export const normalizePositionlessLineup = (lu, site, sport) => {
  const rosterPositions = GET_POSITIONLESS_ROSTER_POSITIONS(lu.length, sport, site)

  const normalizedLU = {}
  // Set the "Position" to luPrefix{i}
  lu.forEach((l, i) => {
    normalizedLU[rosterPositions[i]] = l
  })

  return normalizedLU
}

export const getPlayerTablePositionsClassic = (site, sport) => {

  if (sport === 'nfl')
    return ["QB", "RB", "WR", "TE", "FLEX", 'DST']
  else if (sport === 'nba') {
    if (site === 'dk')
      return ['PG', 'SG', 'SF', 'PF', 'C', 'G', 'F', 'UTIL']
    else if (site === 'fd')
      return ['PG', 'SG', 'SF', 'PF', 'C']
  } else if (sport === 'mlb') {
    if (site === 'dk') {
      return ['P', 'C', '1B', '2B', '3B', 'SS', 'OF']
    } else if (site === 'fd') {
      return ['P', 'C', '1B', '2B', 'SS', '3B', 'OF', 'UTIL']
    }
  } else {
    // don't show any positions other than All
    return []
  }

  throw Error(`no positions for ${site} : ${sport}`)
}

export const getFlexPositionsForSport = (sport) => {
  if (sport === 'nfl') {
    return ['RB', 'WR', 'TE']
  } else if (sport === 'mlb') {
    return ['C', '1B', '2B', 'SS', '3B', 'OF']
  } else if (sport === 'nba') {
    return ['PG', 'SG', 'SF', 'PF', 'C']
  }
}