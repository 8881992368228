import {
  login,
  signup
} from '../actions/auth'

const initialState = {
  loading: false,
  failedLogin: false,
  failedSignup: false,
  errorMessage: null,
  loggedin: false,
  name: null,
  loadingCheck: true,
  admin: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case login.FETCH:
      return {...initialState, loading: true, loggedin: false, loadingCheck: false}
    case login.RECEIVE:
      return {...state, loading: false, loggedin: true, failedLogin: false, name: action.payload.name, admin: action.payload.admin, loadingCheck: false}
    case login.LOGOUT_COMPLETE:
      return {...state, ...initialState, loading: false, loadingCheck: false}
    case login.FAIL_FETCH:
      return {...state, loading: false, loggedin: false, errorMessage: action.payload.error, failedLogin: true, loadingCheck: false}
    case login.CHECK:
      return {...initialState, loadingCheck: true, loggedin: false}
    case signup.FETCH:
      return {...initialState, loading: true, loggedin: false, failedSignup: false, loadingCheck: false}
    case signup.RECEIVE:
      return {...state, loading: false, loggedin: true, loadingCheck: false}
    case signup.FAIL_FETCH:
      return {...state, loading: false, loggedin: false, errorMessage: action.payload.error, failedSignup: true}
    default:
      return state
  }
}

export const getAuth = state => state.auth
