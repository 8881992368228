import { EXP_SETTINGS_KEY } from '../constants/local-storage-keys'
import isNull from './isNull'

const mergePlayerTables = async (oldPlayerTable, updatedPlayerTable) => {
  let DEFAULT_MIN_EXP = 0
  let DEFAULT_MAX_EXP = 100
  let NEW_DEFAULT_MAX_EXP = null
  const exposureDefaults = JSON.parse(localStorage.getItem(EXP_SETTINGS_KEY))
  if (exposureDefaults && exposureDefaults.zeroed)
    NEW_DEFAULT_MAX_EXP = 0

  const oldPlayerTableMap = {}
  oldPlayerTable.forEach(player => {
    oldPlayerTableMap[player.Id] = {
      UserProj: player.UserProj,
      UserOwn: player.UserOwn,
      UserMins: player.UserMins,
      MinExp: player.MinExp,
      MaxExp: player.MaxExp
    }
  })

  return updatedPlayerTable.map(_player => {
    const player = { ..._player }
    const _oldPlayer = oldPlayerTableMap[player.Id]
    if (_oldPlayer) {
      // If they have user projection do our hack
      if (typeof _oldPlayer.UserProj !== 'undefined') {
        player.UserProj = _oldPlayer.UserProj
        player.HouseProjPts = _player.ProjPts
        player.ProjPts = _oldPlayer.UserProj
      }

      if (typeof _oldPlayer.UserMins !== 'undefined') {
        player.UserMins = _oldPlayer.UserMins
        player.HouseMin = _player.Min
        player.Min = _oldPlayer.UserMins
      }

      if (typeof _oldPlayer.UserOwn !== 'undefined') {
        player.UserOwn = _oldPlayer.UserOwn
        player.HouseOwn = _player.ProjOwn
        player.ProjOwn = _oldPlayer.UserOwn
      }

      player.MinExp = _oldPlayer.MinExp
      player.MaxExp = _oldPlayer.MaxExp
    }
    if (
      !isNull(NEW_DEFAULT_MAX_EXP) &&
      Number(player.MinExp) === DEFAULT_MIN_EXP &&
      Number(player.MaxExp) === DEFAULT_MAX_EXP
    ) {
      player.MinExp = DEFAULT_MIN_EXP
      player.MaxExp = NEW_DEFAULT_MAX_EXP
    }

    return player
  })
}

export default mergePlayerTables
