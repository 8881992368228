import React from 'react'
import { Select, DatePicker } from 'antd'
import dayjs from 'dayjs';
import moment from 'moment-timezone'
import styled from 'styled-components'
import { getSiteImg } from '../utils/team-images-loader'
import { getWeeklyDateRange } from '../utils/get-default-date-info';

const DrowdownContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const SmallCarrot = styled.span`
  font-size: 10px;
  margin-left: -5px;
`

const siteToLogoMap = {
  'dk': (
    getSiteImg('dk')
  ),
  'fd': (
    getSiteImg('fd')
  )
}

const dateFormat = 'YYYY-MM-DD';

const WeekLongDatePicker = ({ selectedDate, selectedSeason, changeDateInfo, site, updateSite }) => {
  const _siteOptions = Object.keys(siteToLogoMap).map(site => {
    return {
      label: <span style={{"padding": "2px"}}>{siteToLogoMap[site]}</span>,
      value: site
    }
  })

  const setNewDate = (newDate) => {
    const _dateStr = newDate.format('YYYY-MM-DD')
    
    const momentObj = moment(_dateStr, dateFormat).tz('America/Los_Angeles')
    const dateRange = getWeeklyDateRange(momentObj)

    changeDateInfo({counter: dateRange, season: selectedSeason})
  }
  return (
    <DrowdownContainer>
      <Select
        style={{marginRight: '5px'}}
        value={site}
        onChange={(s) => {updateSite(s)}}
        options={_siteOptions}
      />
      <DatePicker 
        placeholder='Current'
        format={dateFormat} 
        allowClear={false} 
        onChange={(d) => {setNewDate(d)}}
        style={{marginRight: '5px'}}
      />
    </DrowdownContainer>
  )
}

export default WeekLongDatePicker
