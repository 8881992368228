import {
  Row,
  Col,
  Card,
  Button,
  Switch,
  Radio
} from 'antd'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import * as lineupActions from '../../../../actions/lineup'
import * as groupActions from '../../../../actions/groups'
import Players from './players'
import isObjEmpty from '../../../../utils/isObjEmpty'

import './Players.css'

const PlayersBox = styled.div`
  margin-top: 25px;
`

class PlayersContainer extends PureComponent {
  state = {
    toggle: 'players',
    showUpdateBanner: false,
    showPresetsActive: false
  }

  exposureChangeValid(rowData) {
    // must be inbetween 0-100
    if (rowData.MaxExp > 100 || rowData.MaxExp < 0 || rowData.MinExp > 100 || rowData.MinExp < 0) return false

    return true
  }

  onExposureChange(rowData) {
    // turn into array
    if (!Array.isArray(rowData))
      rowData = [rowData]

    const _players = [
      ...this.props.players.data
    ]

    rowData.forEach(r => {
      // Skip rows that have invalid changes
      if (!this.exposureChangeValid(r)) return

      const _playerIndex = _players.findIndex(p => p.Id === r.Id)
      if (_playerIndex < 0) {
        throw Error('No player with id ' + r.Id)
      }

      _players[_playerIndex] = {
        ...r
      }
    })

    this.props.updatePlayersTable({
      payload: _players,
      rowData,
      slate: this.props.slate,
      site: this.props.site,
      sport: this.props.sport,
      season: this.props.season,
      counter: this.props.counter
    })
  }

  setPreset = (index) => {
    // Use default
    if (Number(index) === 0) {
      const _settings = this.props.presets.data[index]

      this.props.updateSettings( { 
        site: this.props.site, 
        slate: this.props.slate, 
        ..._settings, 
        sport: this.props.sport, 
        season: this.props.season,
        counter: this.props.counter
      } )
    } else {
      // Custom Preset
      const customPresets = this.props.customPresets[index - 1]
      // Do we have Players
      if (!isObjEmpty(customPresets.presets.players)) {
        const _players = [
          ...this.props.players.data
        ]

        const rowData = []

        customPresets.presets.players.forEach(p => {
          const _playerIndex = _players.findIndex(r => p.Id === r.Id)
          if (_playerIndex >= 0) {
            _players[_playerIndex] = {
              ..._players[_playerIndex],
              UserProj: p.UserProj || _players[_playerIndex].UserProj,
              UserOwn: p.UserOwn || _players[_playerIndex].UserOwn,
              Boost: p.Boost || _players[_playerIndex].Boost,
              MinExp: p.MinExp || _players[_playerIndex].MinExp,
              MaxExp: p.MaxExp || _players[_playerIndex].MaxExp,
            }
          }
        })

        this.props.updatePlayersTable({
          payload: _players,
          slate: this.props.slate,
          site: this.props.site,
          sport: this.props.sport,
          season: this.props.season,
          counter: this.props.counter
        })
      }

      // Do we have general settings
      if (!isObjEmpty(customPresets.presets.site_settings)) {
        this.props.updateSettings( { 
          site: this.props.site, 
          slate: this.props.slate, 
          ...customPresets.presets.site_settings, 
          sport: this.props.sport, 
          season: this.props.season,
          counter: this.props.counter
        } )
      }

      // Do we have team stacks
      if (!isObjEmpty(customPresets.presets.team_stacks)) {
        this.props.updateTeamStacks( { 
          site: this.props.site, 
          slate: this.props.slate, 
          _teamStacks: customPresets.presets.team_stacks, 
          sport: this.props.sport, 
          season: this.props.season,
          counter: this.props.counter
        } )
      }

      // Do we have groups
      if (!isObjEmpty(customPresets.presets.groups)) {
        this.props.replaceGroups( { 
          site: this.props.site, 
          slate: this.props.slate, 
          groups: customPresets.presets.groups, 
          sport: this.props.sport, 
          season: this.props.season,
          counter: this.props.counter
        } )
      }

    }

    this.setState({
      showPresetsActive: true
    })
    setTimeout(() => {
      this.setState({
        showPresetsActive: false
      })
    }, 2000)
  }

  render() {
    return (
      <PlayersBox>
        <Players
          clearPlayerData={this.props.clearPlayerData}
          zeroExposures={this.props.zeroExposures}
          site={this.props.site}
          slate={this.props.slate}
          onExposureChange={this.onExposureChange.bind(this)}
          showdown={this.props.showdown}
          counter={this.props.counter}
          season={this.props.season}
          sport={this.props.sport}
        />
      </PlayersBox>
    )
  }
}

export default connect(
  state => ({
    players: state.lineup.players,
    loggedin: state.auth.loggedin,
    teamStacks: state.lineup.teamStacks,
    subscription: state.account.subscription,
    settings: state.lineup.settings,
    positionCounts: state.analysis.positionCounts,
    presets: state.lineup.presets,
    customPresets: state.admin.customPresets,
    admin: state.admin,
  }),
  {
    updatePlayersTable: lineupActions.updatePlayersTable,
    updateSettings: lineupActions.updateSettings,
    updateTeamStacks: lineupActions.updateTeamStacks,
    replaceGroups: groupActions.replaceGroups
  }
)(PlayersContainer)
