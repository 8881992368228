import React from 'react'
import { Row, Col, Modal } from 'antd'

export default ({warnings, showModal, setShowModal }) => {

  return (
    <Modal
      open={showModal}
      onCancel={() => setShowModal(false)}
      width={'50%'}
      style={{top: '200px'}}
      footer={null}
    >
      <ul>
      { warnings.map(w => (
        <li>
          {w}
        </li>
      ))}
      </ul>
    </Modal>
  )
}
