import { createActions } from 'lessdux'

export const account = createActions('ACCOUNT')
export const password = {
  NEW: 'PASSWORD_NEW',
  SAVED: 'PASSWORD_SAVED',
  FAIL_UPDATE: 'PASSWORD_FAILED_SAVE',
}
export const forgotPassword = createActions('FORGOT_PASSWORD')
export const resetPassword = createActions('RESET_PASSWORD')
export const subscription = {
  ...createActions('SUBSCRIPTION'),
  UPDATE: 'SUBSCRIPTION_NEW',
  SAVED: 'SUBSCRIPTION_SAVED',
  FAIL_SAVE: 'SUBSCRIPTION_FAILED_SAVE',
  NEW_CARD: 'SUBSCRIPTION_NEW_CARD'
}
export const promoCode = {
  CHECK: 'CHECK_PROMO_CODE',
  RECEIVE_CHECK: 'RECEIVE_CHECK_PROMO_CODE'
}

export const fetchAccountInfo = () => {
  return {
    type: account.FETCH
  }
}

export const updatePassword = ({ oldPassword, newPassword }) => {
  return {
    type: password.NEW,
    oldPassword,
    newPassword
  }
}

export const fetchForgotPassword = ({ email }) => {
  return {
    type: forgotPassword.FETCH,
    email
  }
}

export const fetchResetPassword = ({ token, password }) => {
  return {
    type: resetPassword.FETCH,
    token,
    password
  }
}

export const fetchSubscription = ({ sport }) => {
  return {
    type: subscription.FETCH,
    sport
  }
}

export const updateSubscription = ({ planName, token, promo, yearly }) => {
  return {
    type: subscription.UPDATE,
    planName,
    token,
    promo,
    yearly
  }
}

export const updateCreditCard = (newCard) => {
  return {
    type: subscription.NEW_CARD,
    newCard
  }
}

export const checkPromoCode = (code) => {
  return {
    type: promoCode.CHECK,
    code
  }
}
