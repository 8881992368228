import { Menu, Table, Col } from 'antd'
import styled from 'styled-components'
import { framed, overridePrimary, overridePlayerText, overrideBackground, overrideColor, overrideTableHighlight } from '../constants/partner'

export const PlayerText = styled.span`
  cursor: pointer;
  font-weight: 600;
  color: ${framed ? (overridePrimary) : '#1d3557'};
  &:hover {
    color: #1890ff;
  }
`
export const StyledMenu = styled(Menu)`
  background: ${framed && overrideBackground ? overrideBackground : null} !important;
  color: ${framed ? (overrideColor || overridePlayerText) : '#fff'} !important;

  .ant-menu-item-selected {
    color: #a8aaba !important;
  }
`

export const StyledTable = styled(Table)`
td {
  text-align: center;
  padding: 2px !important;
  background: ${framed && overrideBackground ? overrideBackground : '#fff'} !important;
}

tr th {
  text-align: center !important;
  background: ${framed && overrideBackground ? overrideBackground : '#fff'} !important;
}

tr:first-child {
  th {
    color: white !important;
    background: ${framed ? overridePrimary : primaryColor} !important;
  }
}

tr.ant-table-row-level-0:hover > td {
  background: ${overrideTableHighlight} !important;
}

*, *:before, *:after {
  -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
}

.divide {
  border-right: 2px solid ${framed ? overridePrimary : primaryColor} !important;
}
`