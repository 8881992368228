import React from 'react'
import { Table, Tooltip } from 'antd'
import styled from 'styled-components'
import {
  LockOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons'
import { CPTDisplay } from '../../../../../../utils/showdown'

const ROW_COLS_KEY = 'row-cols'

const RightDividerRow = styled.div`
  border-right: 1px solid black;
`

const classicCols = [
  {
    'title': 'Position',
    'dataIndex': ROW_COLS_KEY,
    'key': ROW_COLS_KEY
  },
  {
    'title': 'QB',
    'dataIndex': 'qb',
    'key': 'qb'
  },
  {
    'title': 'RB',
    'dataIndex': 'rb',
    'key': 'rb'
  },
  {
    'title': 'WR',
    'dataIndex': 'wr',
    'key': 'wr'
  },
  {
    'title': 'TE',
    'dataIndex': 'te',
    'key': 'te'
  },
  {
    'title': 'DST',
    'dataIndex': 'dst',
    'key': 'dst',
  },
  {
    'title': 'All',
    'dataIndex': 'all',
    'key': 'all'
  }
]
const showdownCols = (site) => [
  {
    'title': 'Position',
    'dataIndex': ROW_COLS_KEY,
    'key': ROW_COLS_KEY
  },
  {
    'title': CPTDisplay[site],
    'dataIndex': 'cpt',
    'key': 'cpt'
  },
  {
    'title': 'FLEX',
    'dataIndex': 'flex',
    'key': 'flex'
  },
  {
    'title': 'All',
    'dataIndex': 'all',
    'key': 'all'
  }
]

const rowCols = [
  "Number in Player Pool",
  (<span>Winning Range</span>),
  "Min Exp.",
  "Max Exp."
]

const StyledTable = styled(Table)`
  .divide {
    border-right: 1px solid black !important;
  }

  .filled {
    background: #1890ff;
  }
`

const InputTooHigh = styled.span`
  &.error {
    color: #ff5252;
  }
`

const Lock = () => (
  <Tooltip title="Upgrade for this feature">
    <LockOutlined />
  </Tooltip>
)

const lowerRange = 'Pct_25'
const upperRange = 'Pct_75'

/**
  POSITIONAL BREAKDOWN TABLE

  pass players
*/
const PositionBreakdownTable = ({ players, permissioned, positionCounts, showdown, site }) => {
  const _data = getFormattedTableFigures(players, permissioned, positionCounts, showdown)
  const columns = showdown ? showdownCols(site) : classicCols

  return (
    <StyledTable
      bordered
      columns={columns}
      dataSource={_data || []} pagination={false}
      size={'small'}
      scroll={{x: '150px'}}
      rowKey ={(_row, i) => {
        return `pre-analysis-pos-breakdown-${i}`
      }}
    />
  )
}

const getFormattedTableFigures = (players, permissioned, positionCounts, showdown) => {
  let exposures = initExposuresObject(showdown)

  players.forEach(_player => {
    if (Number(_player["MaxExp"]) > 0) {
      // Add to position
      if (showdown) {
        exposures[_player.RosterPosition].count++
        exposures[_player.RosterPosition].minExp+=Number(_player["MinExp"])
        exposures[_player.RosterPosition].maxExp+=Number(_player["MaxExp"])
      } else {
        _player.Positions.forEach(pos => {
          exposures[pos].count++
          exposures[pos].minExp+=Number(_player["MinExp"])
          exposures[pos].maxExp+=Number(_player["MaxExp"])
        })
      }

      // Add to All
      exposures["ALL"].count++
      exposures["ALL"].minExp+=Number(_player["MinExp"])
      exposures["ALL"].maxExp+=Number(_player["MaxExp"])
    }
  })

  let cols

  if (showdown) {
    cols = getShowdownCols(exposures, players, permissioned, positionCounts, showdown)
  } else {
    cols = getClassicCols(exposures, players, permissioned, positionCounts, showdown)
  }

  return cols
}

const initExposuresObject = (showdown) => {
  if (!showdown) {
    return {
        'QB': {
          "count": 0,
          "minExp": 0,
          "maxExp": 0
        },
        'RB': {
          "count": 0,
          "minExp": 0,
          "maxExp": 0
        },
      'WR': {
        "count": 0,
        "minExp": 0,
        "maxExp": 0
      },
      'TE': {
        "count": 0,
        "minExp": 0,
        "maxExp": 0
      },
      'DST': {
        "count": 0,
        "minExp": 0,
        "maxExp": 0
      },
      'ALL': {
        "count": 0,
        "minExp": 0,
        "maxExp": 0
      }
    }
  } else {
    return {
      'CPT': {
        "count": 0,
        "minExp": 0,
        "maxExp": 0
      },
      'FLEX': {
        "count": 0,
        "minExp": 0,
        "maxExp": 0
      },
      'ALL': {
        "count": 0,
        "minExp": 0,
        "maxExp": 0
      }
    }
  }
}

const getShowdownCols = (exposures, players, permissioned, positionCounts, showdown) => {
  const cols = []
  // Add counts
  const counts = {
    [ROW_COLS_KEY]: rowCols[0],
    'cpt': exposures['CPT']['count'],
    'flex': exposures['FLEX']['count'],
    'all': exposures['ALL']['count'],
  }
  cols.push(counts)
  // Add min exp
  cols.push({
    [ROW_COLS_KEY]: rowCols[2],
    'cpt': exposures['CPT']['minExp'].toFixed(0),
    'flex': exposures['FLEX']['minExp'].toFixed(0),
    'all': exposures['ALL']['minExp'].toFixed(0)
  })
  // Add max exp
  cols.push({
    [ROW_COLS_KEY]: rowCols[3],
    'cpt': exposures['CPT']['maxExp'].toFixed(0),
    'flex': exposures['FLEX']['maxExp'].toFixed(0),
    'all': exposures['ALL']['maxExp'].toFixed(0)
  })

  return cols
}

const getClassicCols = (exposures, players, permissioned, positionCounts, showdown) => {
  const cols = []
  // Add counts
  const counts = {
    [ROW_COLS_KEY]: rowCols[0],
    'qb': exposures['QB']['count'],
    'rb': exposures['RB']['count'],
    'wr': exposures['WR']['count'],
    'te': exposures['TE']['count'],
    'dst': exposures['DST']['count'],
    'all': exposures['ALL']['count'],
  }
  cols.push(counts)
  // Add winning range
  if (permissioned) {
    const ranges = {
      [ROW_COLS_KEY]:
      <Tooltip
        title={'The range of players that the majority of historical winners used in their player pools. An error will be displayed if you are outside of the boundaries of any previous past winner.'}
        placement='topRight'
      >
        {rowCols[1]}
        <span style={{fontSize: '12px', marginLeft: '3px'}}><QuestionCircleOutlined /></span>
      </Tooltip>,
    }
    const pos = ['qb', 'rb', 'wr', 'te', 'dst', 'all']
    pos.forEach(k => {
      try {
        let outOfRange = false
        if (positionCounts[k.toUpperCase()] && Number(counts[k]) > Number(positionCounts[k.toUpperCase()]['Max']) || Number(counts[k]) < Number(positionCounts[k.toUpperCase()]['Min']))
          outOfRange = true

        ranges[k] = positionCounts[k.toUpperCase()] ? (
          <InputTooHigh className={outOfRange ? 'error' : ''}>
            {positionCounts[k.toUpperCase()][lowerRange]} - {positionCounts[k.toUpperCase()][upperRange]}
          </InputTooHigh>
        ) : ''
      } catch (e) {
        console.log(e)
        ranges[k] = ''
      }
    })
    cols.push(ranges)
  }
  else
    cols.push({
      [ROW_COLS_KEY]: (
        <Tooltip
          title={'The range of players that the majority of historical winners used in their player pools. An error will be displayed if you are outside of the boundaries of any previous past winner.'}
          placement='topRight'
        >
          {rowCols[1]}
          <span style={{fontSize: '12px', marginLeft: '3px'}}><QuestionCircleOutlined /></span>
        </Tooltip>
      ),
      'qb': (<Lock />),
      'rb': (<Lock />),
      'wr': (<Lock />),
      'te': (<Lock />),
      'dst': (<Lock />),
      'all': (<Lock />),
    })
  // Add empty row
  cols.push({
    [ROW_COLS_KEY]: '',
    'qb': '',
    'rb': '',
    'wr': '',
    'te': '',
    'dst': '',
    'all': '',
  })
  // Add min exp
  cols.push({
    [ROW_COLS_KEY]: rowCols[2],
    'qb': exposures['QB']['minExp'].toFixed(0),
    'rb': exposures['RB']['minExp'].toFixed(0),
    'wr': exposures['WR']['minExp'].toFixed(0),
    'te': exposures['TE']['minExp'].toFixed(0),
    'dst': exposures['DST']['minExp'].toFixed(0),
    'all': exposures['ALL']['minExp'].toFixed(0),
  })
  // Add max exp
  cols.push({
    [ROW_COLS_KEY]: rowCols[3],
    'qb': exposures['QB']['maxExp'].toFixed(0),
    'rb': exposures['RB']['maxExp'].toFixed(0),
    'wr': exposures['WR']['maxExp'].toFixed(0),
    'te': exposures['TE']['maxExp'].toFixed(0),
    'dst': exposures['DST']['maxExp'].toFixed(0),
    'all': exposures['ALL']['maxExp'].toFixed(0),
  })

  return cols
}

export default PositionBreakdownTable
