import React, { useState, Component } from 'react'
import { Row, Col, Card, Icon } from 'antd'
import {
  SlidersOutlined,
  WarningOutlined
} from '@ant-design/icons'
import styled from 'styled-components'
import {
  HEAT_MAP_NEGATIVE_COLOR_SCALE,
  HEAT_MAP_POSITIVE_COLOR_SCALE,
  getColorForValue
} from './utils/color'
import { connect } from 'react-redux'

const ScoreCircle = styled.div`
  border-radius: 50px;
  width: 100px;
  padding: 20px;
  font-size: 40px;
  text-align: center;
`
const ClickableContainer = styled.div`
  font-weight: 400;
  font-size: 20px;
  margin-top: 5px;
`
const ClickableText = styled.div`
  cursor: pointer;

  display: inline-block;

  &:hover {
    text-decoration: underline;
  }
`
const StyledRow = styled(Row)`
  &.blurry-text {
    color: transparent;
    text-shadow: 0 0 10px rgba(0,0,0,0.5);

    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;

    /*
     Introduced in IE 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
    */
    -ms-user-select: none;
    user-select: none;
  }
`

const getLeverage = (actualExp, projOwn) => {
  return ((actualExp) - Number(projOwn)).toFixed(2)
}

const getTopPlayers = (players, showdown) => {
  const _leverages = []

  players.forEach(p => {
    if (p.MaxExp > 0 || Number(p.ProjOwn) !== 0) {
      _leverages.push(
        {
          name: `${p.Name}${showdown ? (
            p.RosterPosition === 'CPT' ? ' (C)': ''
          ) : ''} | ${p.Position}`,
          leverage: getLeverage(p.Exp, p.ProjOwn)
        }
      )
    }
  })

  const sortedLeverages = _leverages.sort((a, b) => (Number(a.leverage) > Number(b.leverage)) ? 1 : -1)
  return sortedLeverages
}

class Overview extends Component {
  render() {
    const sortedLeverages = getTopPlayers(this.props.exposureStats || [], this.props.showdown )

    return (
      <Card>
        <Row>
          <Col lg={16} offset={4}>
            <Row>
              <Col lg={10} offset={2}>
                <h4>Overweight</h4>
                {
                  sortedLeverages.slice(sortedLeverages.length-5,sortedLeverages.length).reverse().map((p, i) => (
                    <StyledRow className={ (!this.props.permissioned && (i > 0)) ? 'blurry-text' : '' } key={`overweight-${i}`}>
                      <Col lg={2}>
                        {i+1}.
                      </Col>
                      <Col lg={14}>
                        {p.name}
                      </Col>
                      <Col lg={8}
                        style=
                        {
                          {
                            backgroundColor: getColorForValue(p.leverage),
                            textAlign: 'center',
                            opacity: '0.75'
                          }
                        }
                      >
                        +{p.leverage}%
                      </Col>
                    </StyledRow>
                  ))
                }
              </Col>
              <Col lg={10} offset={2}>
                <h4>Underweight</h4>
                  {
                    sortedLeverages.slice(0,5).map((p, i) => (
                      <StyledRow className={ (!this.props.permissioned && (i > 0)) ? 'blurry-text' : '' } key={`underweight-${i}`}>
                        <Col lg={2}>
                          {i+1}.
                        </Col>
                        <Col lg={14}>
                          {p.name}
                        </Col>
                        <Col lg={8}
                          style=
                          {
                            {
                              backgroundColor: getColorForValue(p.leverage),
                              textAlign: 'center',
                              opacity: '0.75'
                            }
                          }
                        >
                          {p.leverage}%
                        </Col>
                      </StyledRow>
                    ))
                  }
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    )
  }
}

export default connect(
  state => ({}),
  {
  }
)(Overview)
