const getPlayerKey = (player) => {
  return `${player.Name}-${player.TeamAbbrev}`
}

// Devante Adams Raiders 
const playersToFilter = {
  'Davante Adams-Raiders': {
    ProjPts: 0,
    ProjOwn: 0
  }
}

export default (players) => {
  return players.map(p => {
    const pk = getPlayerKey(p)

    const newPlayer = {...p}

    if (playersToFilter[pk]) {
      newPlayer.ProjPts = playersToFilter[pk].ProjPts
      newPlayer.ProjOwn = playersToFilter[pk].ProjOwn
    }

    return newPlayer
  })
}