import { takeLatest, put, call, select } from 'redux-saga/effects'

import * as lineupActions from '../actions/lineup'
import { getAuth } from '../reducers/auth'
import { getPlayers, getSettings, getSavedLineups, getOptLineups } from '../reducers/lineup'
import { week, season } from '../utils/nfl-week'
import { localStorageKeyPlayers, localStorageKeyStacks } from '../constants/local-storage-keys'
import mergePlayerTableDbProjections from '../utils/merge-player-table-db-proj'
import mergePlayerTables from '../utils/merge-player-table'
import { reorderNFLRuns, reorderMLBRuns, reorderNBARuns, reorderPositionlessRuns } from '../utils/reorder-runs'
import {
  sanitizePlayerTableRow,
  sanitizePlayerTable
 } from '../utils/sanitize-data'
import {
  adjustPlayers
} from '../utils/showdown'
import { partnerURI } from '../constants/api'
import getInitialSettingsForSport from '../utils/sport-settings'
import { getToken } from '../utils/tokens'
import { adjustPlayersOWS } from '../utils/player-table-adjustments'
import playerFilter from '../components/player-filter'

export const DEFAULT_COMPANY = 'ows'


export function calcESTDay() {
    const d = new Date()
    const utc = d.getTime() + (d.getTimezoneOffset() * 60000)
    const nd = new Date(utc + (3600000*-5)) // Timezone EST is -5 (or not depending on daylight savings but close enough)
    return nd
}

const _dev = process.env.REACT_APP_ENVIRONMENT === 'development'
// const _dev = false

const getLocalStorageKeyForSport = (keyFunction, slate, site, sport) => {
  let localStorageKey
  switch (sport) {
    case 'nfl':
      localStorageKey = keyFunction(slate, site, sport, `${season}-${week}`)
      break
    default:
      break
  }

  return localStorageKey
}

export const getDayKeyFromDate = (_date) => (
  `${_date.getMonth() + 1}-${_date.getDate()}-${_date.getFullYear()}`
)

/**
 * Fetches all the players
 * @returns {object[]} - The player data.
 */
export function* fetchPlayers({ site, slate, season, counter, sport='nfl', free=false, showdown=false }) {
  let responseBody
  // Fetch from localStorage if possible
  let _cachedPlayers
  const localStorageKey = getLocalStorageKeyForSport(
    localStorageKeyPlayers,
    slate,
    site,
    sport
  )

  try {
    if (localStorage.getItem(localStorageKey))
      _cachedPlayers = JSON.parse(localStorage.getItem(localStorageKey))
  } catch (e) {}

  const _auth = yield select(getAuth)
  const token = getToken()

  let uri = `${_dev ? partnerURI : partnerURI}/${sport}/players/${site}/${showdown ? 'showdown' : 'classic'}/${DEFAULT_COMPANY}/${slate}/${season}/${counter}`

  let players = []
  let updated_at = undefined
  let maxSal

  try {
    let response
    response = yield call(fetch, uri, {
      method: 'GET',
      headers: {
        authorization: token
      }
    })

    responseBody = yield call([response, response.json])

    players = responseBody.players
    updated_at = responseBody.updated_at
    if (responseBody.max_salary)
      maxSal = responseBody.max_salary
  } catch (e) {
    console.error(e)
  }

  // Adjust projections if necessary
  if (showdown) {
    players = yield call(adjustPlayers, players)
  }

  players = yield call(adjustPlayersOWS, players)
  // adjust players manually
  players = yield call(playerFilter, players)

  if (_auth.loggedin) {
    let uri = `${partnerURI}/${sport}/user-projections/${DEFAULT_COMPANY}/${site}/${slate}/${season}/${counter}`
    let playerProjectionsResponse
    try {
      const response = yield call(fetch, uri, {
        method: 'GET',
        headers: {
          authorization: token
        }
      })
      playerProjectionsResponse = yield call([response, response.json])
      players = yield call(mergePlayerTableDbProjections, players, playerProjectionsResponse, (sport === 'nba' ? true : false))
    } catch (e) {}
  } else {
    // Hack to update to new projections/ownership without losing stored
    players = yield call(mergePlayerTables, _cachedPlayers || [], players)

    // NOTE set default max exp to 100 if not set/saved
    players = yield call((players) => {
      return players.map((player) => {
        if (!player.MaxExp)
          player.MaxExp = 100
        if (!player.MinExp)
          player.MinExp = 0
        return player
      })
    }, players)
  }

  // Split position and add Boost if not already added
  players = yield call((players) => {
    return players.map(p => ({
      ...p,
      Positions: p.Position ? p.Position.split("/") : undefined,
      Boost: p.Boost || 0
    }))
  }, players)

  yield put({ type: lineupActions.players.RECEIVE, payload: players, slate, site, updated_at, maxSal })

  // If maxSal is different, set it here
  if (maxSal) {
    yield put({ type: lineupActions.settings.OVERRIDE, payload: { maxSal, maxSalDefault: maxSal, minSal: 0 }, sport, site, slate, showdown })
  }
}

let delayedProjUpdate

function* updatePlayerTable({ payload, rowData, site, slate, counter, season, sport='nfl', showdown=false }) {
  const _auth = yield select(getAuth)
  // if we are logged in reflect the changes in the db
  if (_auth.loggedin) {
    // Clear timeout if one is set
    clearTimeout(delayedProjUpdate)
    delayedProjUpdate = setTimeout(async () => {
      const token = getToken()
      let uri = `${partnerURI}/${sport}/user-projections/${DEFAULT_COMPANY}/${site}/${slate}/${season}/${counter}/${new Date().getTime()}`
      let _scrubbedData
      if (Array.isArray(rowData)) {
        _scrubbedData = rowData.map(_row => (
          sanitizePlayerTableRow(_row, showdown)
        ))
      } else {
        _scrubbedData = sanitizePlayerTableRow(rowData, showdown)
      }
      try {
        const response = await fetch(uri, {
          method: 'POST',
          body: JSON.stringify(_scrubbedData),
          headers: {
            authorization: token,
            'Content-Type': 'application/json'
          }
        })
        await response.json()
      } catch (e) {
        console.error("failed to update user projections")
      }
    }, 250)
  } else {
    const localStorageKey = getLocalStorageKeyForSport(
      localStorageKeyPlayers,
      slate,
      site,
      sport
    )
    localStorage.setItem(localStorageKey, JSON.stringify(payload))
  }
}

function* removePlayerProjections({ site, slate, counter, season, sport='nfl' }) {
  const _auth = yield select(getAuth)
  // if we are logged in reflect the changes in the db
  if (_auth.loggedin) {
    const token = getToken()
    let uri = `${partnerURI}/${sport}/user-projections/${DEFAULT_COMPANY}/${site}/${slate}/${season}/${counter}`
    try {
      const response = yield call(fetch, uri, {
        method: 'DELETE',
        headers: {
          authorization: token,
          'Content-Type': 'application/json'
        }
      })
      yield call([response, response.json])
    } catch (e) {
      throw new Error('unable to remove player projections')
    }
  } else {
    const localStorageKey = getLocalStorageKeyForSport(
      localStorageKeyPlayers,
      slate,
      site,
      sport
    )
    localStorage.setItem(localStorageKey, undefined)
  }
}

export function* fetchTeamStacks({ players, slate, site, counter, season, sport='nfl' }) {
  let responseBody = []
  const localStorageKey = getLocalStorageKeyForSport(
    localStorageKeyStacks,
    slate,
    site,
    sport
  )

  let _cachedStacks
  let token

  const _auth = yield select(getAuth)
  if (_auth.loggedin) {
    token = getToken()
  }
  try {
    if (localStorage.getItem(localStorageKey))
      _cachedStacks = JSON.parse(localStorage.getItem(localStorageKey))
  } catch (e) {}


  const uri = `${partnerURI}/${sport}/team-stacks/${DEFAULT_COMPANY}/${site}/${slate}/${season}/${counter}`

  try {
    const response = yield call(fetch, uri, {
      method: 'POST',
      body: JSON.stringify({ players }),
      headers: {
        'Content-Type': 'application/json',
        authorization: token,
      }
    })
    responseBody = yield call([response, response.json])
    // if (_cachedStacks)
    //   responseBody = yield call(mergeTeamStacksTable, _cachedStacks, responseBody)
  } catch (e) {
    throw new Error('unable to fetch team stacks')
  }

  // if (_cachedStacks)
  //   responseBody = yield call(mergeTeamStacksTable, _cachedStacks, responseBody)

  yield put({type: lineupActions.teamStacks.RECEIVE, payload: responseBody, slate, site})
}

function* updateTeamStacks({ payload, rowData, site, slate, counter, season, sport='nfl' }) {
  const _auth = yield select(getAuth)
  // if we are logged in reflect the changes in the db
  if (_auth.loggedin) {
    const token = getToken()
    let uri = `${partnerURI}/${sport}/user-team-stacks/${DEFAULT_COMPANY}/${site}/${slate}/${season}/${counter}`
    try {
      const response = yield call(fetch, uri, {
        method: 'POST',
        body: JSON.stringify({
          teamStacks: payload
        }),
        headers: {
          authorization: token,
          'Content-Type': 'application/json'
        }
      })
      yield call([response, response.json])
    } catch (e) {
      throw new Error('unable to update team stacks db')
    }
  } else {
    const localStorageKey = getLocalStorageKeyForSport(
      localStorageKeyStacks,
      slate,
      site,
      sport
    )
    localStorage.setItem(localStorageKey, JSON.stringify(payload))
  }
}

function* removeTeamStacks({ site, slate, counter, season, sport='nfl' }) {
  const _auth = yield select(getAuth)
  // if we are logged in reflect the changes in the db
  if (_auth.loggedin) {
    const token = getToken()
    let uri = `${partnerURI}/${sport}/user-team-stacks/${DEFAULT_COMPANY}/${site}/${slate}/${season}/${counter}`
    try {
      const response = yield call(fetch, uri, {
        method: 'DELETE',
        headers: {
          authorization: token,
          'Content-Type': 'application/json'
        }
      })
      yield call([response, response.json])
    } catch (e) {
      throw new Error('unable to remove player projections')
    }
  } else {
    const localStorageKey = getLocalStorageKeyForSport(
      localStorageKeyStacks,
      slate,
      site,
      sport
    )

    localStorage.setItem(localStorageKey, undefined)
  }

  const players = yield select(getPlayers)

  yield put({type: lineupActions.teamStacks.FETCH, players, slate, site, sport, counter, season })
}

export function* fetchExposureStats({ lineups, players, luSize=9, sport='nfl' }) {
  const uri = `${partnerURI}/${sport}/exposure-stats/${luSize}`
  const sanitized_players = yield call(sanitizePlayerTable, players)
  const _fetch = () => fetch(
    uri,
    {
      method: 'POST',
      body: JSON.stringify({ players: sanitized_players, lineups }),
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )

  let responseBody
  try {
    const response = yield call(_fetch)
    responseBody = yield call([response, response.json])
    yield put({type: lineupActions.exposureStats.RECEIVE, payload: responseBody})
  } catch (e) {
    throw new Error('unable to fetch exposure stats')
  }

}

export function* fetchLineupStats({ lineups, slateSize, counter, season, sport='nfl' }) {
  const uri = `${partnerURI}/${sport}/summary-data/${season}/${counter}`
  const _fetch = () => fetch(
    uri,
    {
      method: 'POST',
      body: JSON.stringify({ lineups, slateSize }),
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )

  let responseBody
  try {
    const response = yield call(_fetch)
    responseBody = yield call([response, response.json])
    yield put({type: lineupActions.lineupStats.RECEIVE, payload: responseBody})
  } catch (e) {
    throw new Error('unable to fetch opt')
  }
}

function* fetchSlates({ site, season, counter, sport='nfl' }) {
  const uri = `${_dev ? partnerURI : partnerURI}/${sport}/${DEFAULT_COMPANY}/slates/${site}/${season}/${counter}`
  // const uri = `${playersURI(site)}/${sport}/slates/${site}/${sport === 'nfl' ? `${season}/` : ''}${week}`
  let responseBody
  try {
    const token = getToken()
    const response = yield call(fetch, uri, {
      method: 'GET',
      headers: {
        authorization: token,
        'Content-Type': 'application/json'
      }
    })
    responseBody = yield call([response, response.json])
  } catch (e) {
    throw new Error('unable to fetch players')
  }
  yield put({ type: lineupActions.slates.RECEIVE, payload: responseBody })
}

// function* fetchAllSlates({ sites, season, counter, sport='nfl' }) {
//   let responses = {}
//   const fetchSlatesForSite = async (site) => {
//     const uri = `${_dev ? baseURIui : playersURI(site)}/${sport}/slates/${site}/${sport === 'nfl' ? `${season}/` : ''}${counter}`
//     // const uri = `${playersURI(site)}/${sport}/slates/${site}/${sport === 'nfl' ? `${season}/` : ''}${week}`
//     const response = await fetch(uri)
//     const responseBody = await response.json()

//     responses[site] = responseBody
//   }

//   try {
//     yield all(sites.map(site => call(fetchSlatesForSite, site)))
//   } catch (e) {
//     throw new Error('unable to fetch slates')
//   }

//   yield put({ type: lineupActions.slates.RECEIVE_ALL, payload: responses })
// }

function* saveLineups({ site, slate, lineups, silent, counter, season, sport='nfl' }) {
  const _auth = yield select(getAuth)
  let _savedLineups = yield select(getSavedLineups)
  _savedLineups = [..._savedLineups]

  if (!Array.isArray(lineups))
    lineups = [lineups]
  
  lineups.forEach(lu => {
    // get index of lu if exists
    const i = _savedLineups.findIndex((e) => {
      return e.lineupHash === lu.lineupHash
    })

    if (lu.saved) {
      if (i === -1)
        _savedLineups.push({
          rawTable: lu.rawTable,
          lineupHash: lu.lineupHash
        })
    } else {
      // remove item
      if (i >= 0)
        _savedLineups.splice(i, 1)
    }
  })
  // if we are logged in save to db
  if (_auth.loggedin) {
    const token = getToken()
    let uri = `${partnerURI}/${sport}/save-lineups/${DEFAULT_COMPANY}/${site}/${slate}/${season}/${counter}`
    try {
      const response = yield call(fetch, uri, {
        method: 'POST',
        body: JSON.stringify({
          lineups: _savedLineups
        }),
        headers: {
          authorization: token,
          'Content-Type': 'application/json'
        }
      })
      let responseBody = yield call([response, response.json])

      if (responseBody.success && !silent) {
        yield put({ type: lineupActions.savedLineups.FETCH, site, slate, sport, counter, season })
      }
    } catch (e) {
      throw new Error('unable to save lineup db')
    }
  }
}

function* fetchSavedLineups({ site, slate, counter, season, sport='nfl' }) {
  const _auth = yield select(getAuth)
  // if we are logged in save to db
  if (_auth.loggedin) {
    const token = getToken()
    let uri = `${partnerURI}/${sport}/save-lineups/${DEFAULT_COMPANY}/${site}/${slate}/${season}/${counter}`
    try {
      const response = yield call(fetch, uri, {
        method: 'GET',
        headers: {
          authorization: token,
          'Content-Type': 'application/json'
        }
      })
      let responseBody = yield call([response, response.json])

      let _lineups = []
      let lineupHashes = {}
      const _lus = []
      let _size
      switch (sport) {
        // want lineups in format: 
        case 'nfl':
          _lineups = yield call(reorderNFLRuns, responseBody.lineups)
          responseBody.lineups.forEach(lu => {
            lineupHashes[lu.lineupHash] = true
          })
          break
        case 'mlb':
          _lineups = yield call(reorderMLBRuns, responseBody.lineups)
          responseBody.lineups.forEach(lu => {
            lineupHashes[lu.lineupHash] = true
          })
        case 'nba':
          _lineups = yield call(reorderNBARuns, responseBody.lineups)
          responseBody.lineups.forEach(lu => {
            lineupHashes[lu.lineupHash] = true
          })
          // _size = 9
          // for (let i = 0; i < _lineups.length / _size; i++) {
          //   _lus.push(
          //     _lineups.slice(i * _size, (i+1) * _size)
          //   )
          // }

          // for (let j = 0; j < _lus.length; j++) {
          //   const _normalizedLU = normalizeLineupNFL(_lus[j])
          //   lineupHashes[lineupHash(_normalizedLU, positionsNFL)] = true
          // }
          break
        default:
          _lineups = yield call(reorderPositionlessRuns, responseBody.lineups)
          responseBody.lineups.forEach(lu => {
            lineupHashes[lu.lineupHash] = true
          })
          break
      }

      yield put({ type: lineupActions.savedLineups.RECEIVE, payload: _lineups, lineupHashes })
    } catch (e) {
      yield put({ type: lineupActions.savedLineups.FAIL_FETCH })
      // throw new Error('unable to fetch saved lineups db')
    }
  }
}

function* fetchPresets({ numTeams, site, contestType = 'classic', sport = 'nfl' }) {
  const uri = `${partnerURI}/${sport}/${contestType}/${site}/presets/${numTeams}`
  const token = getToken()
  const _fetch = () => fetch(
    uri,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      },
    }
  )

  let responseBody
  try {
    const response = yield call(_fetch)
    responseBody = yield call([response, response.json])

    yield put({type: lineupActions.presets.RECEIVE, payload: responseBody.presets})
  } catch (e) {
    throw new Error('unable to fetch opt')
  }
}

const showdownOverwriteSettings = ["maxUnderOwn_1", "maxUnderOwn_2", "maxUnderOwn_3", "maxUnderOwn_4", "maxUnderOwn_5", "maxTotalOwn"]

function* fetchSiteSettings({ site, counter, season, sport='nfl', showdown=false, slate="Main" }) {
  const token = getToken()
  const _auth = yield select(getAuth)
  // get defaults
  const defaults_uri = `${partnerURI}/${sport}/site-settings/${site}`
  const saved_settings_uri = `${partnerURI}/${sport}/${DEFAULT_COMPANY}/${site}/${slate}/${season}/${counter}/user-settings`

  let saved_response
  let saved_response_body = {error: true}
  if (_auth.loggedin) {
    saved_response = yield call(fetch, saved_settings_uri, { headers: { authorization: token } })
    saved_response_body = yield call([saved_response, saved_response.json])
  }
  
  const defaults_response = yield call(fetch, defaults_uri)
  const defaults_response_body = yield call([defaults_response, defaults_response.json])
  const defaults = defaults_response_body[showdown ? 'showdown' : 'classic'] || {}

  // Make copy of saved settings
  let blended_settings = {...saved_response_body} || {}

  Object.keys(defaults).forEach(default_key => {
    // set the default keys
    blended_settings[`${default_key}Default`] = defaults[default_key]
    // If user has no updated key -- use default
    if (!saved_response_body[default_key]) {
      blended_settings[default_key] = defaults[default_key]
    }
  })
  // If we don't have any saved settings yet, we need to use cached/initial
  if (saved_response_body.error || !token || Object.keys(saved_response_body).length === 0) {
    // Get initial settings -- if users already have saved settings keep them. Can remove after this week
    const initial_settings = yield call(getInitialSettingsForSport, sport, site, slate, false)

    Object.keys(initial_settings).forEach(setting_key => {
        // use the defaults we get from the server, but overwrite default initial settings
        blended_settings[setting_key] = blended_settings[setting_key] || initial_settings[setting_key]
      }
    )
  }

  yield put({ type: lineupActions.settings.RECEIVE, payload: blended_settings, sport, site, slate, showdown })
}

function* resetSiteSettings({ site, slate, showdown, counter, season, sport='nfl'}) {  
  const initial_settings = yield call(getInitialSettingsForSport, sport, site, slate, false)
  const defaults_response = yield call(fetch, `${partnerURI}/${sport}/site-settings/${site}`)
  const defaults_response_body = yield call([defaults_response, defaults_response.json])

  const defaults = defaults_response_body[showdown ? 'showdown' : 'classic']
  const blended_settings = {
    ...initial_settings,
    ...defaults
  }
  Object.keys(defaults).forEach(default_key => {
    // set the default keys
    blended_settings[`${default_key}Default`] = defaults[default_key]
  })

  // save these new settings
  yield put({ type: lineupActions.settings.UPDATE, payload: blended_settings, sport, site, slate, showdown, counter, season })
  // re-fetch the settings for the user
  // yield put({ type: lineupActions.settings.FETCH, sport, site, slate, showdown })
}

let delayedSettingUpdate

function* updateSiteSettings({ site, slate, payload, season, counter, sport='nfl' }) {
  let _currentSettings = yield select(getSettings)
  const _auth = yield select(getAuth)

  if (_auth.loggedin) {
    clearTimeout(delayedSettingUpdate)
    delayedSettingUpdate = setTimeout(async () => {
      const token = getToken()
      // deep copy
      _currentSettings = JSON.parse(JSON.stringify(_currentSettings))
      Object.keys(payload).forEach(k => {
        _currentSettings[k] = payload[k]
      })

      const uri = `${partnerURI}/${sport}/${DEFAULT_COMPANY}/${site}/${slate}/${season}/${counter}/user-settings`
      const _fetch = () => fetch(
        uri,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            authorization: token
          },
          body: JSON.stringify({
            settings: _currentSettings
          })
        }
      )

      let responseBody
      try {
        const response = await _fetch()
        responseBody = await response.json()

        // yield put({ type: lineupActions.settings.RECEIVE_UPDATE, payload: responseBody.settings })
      } catch (e) {
        throw new Error('unable to update settings')
      }
    }, 250)
  }
}

function* replaceLineup({ record, site, slate }) {
  const optLUs = yield select(getOptLineups)

  const runID = record["rawTable"][0]["Run"]
  // Find start index of run
  let startIndex = -1
  for (let i = 0; i < optLUs.length - 1; i++) {
    if (optLUs[i]["Run"] === runID && startIndex === -1) {
      startIndex = i
      break
    }
  }
  // copy over LU
  const optLUsCopy = [...optLUs]
  for (let j=0; j < record.length - 1; j++) {
    optLUsCopy[startIndex + j] = record[j]
  }

  yield put(({
    type: lineupActions.opt.RECEIVE,
    payload: optLUsCopy,
    site: site,
    slate: slate
  }))
}

/**
 * The root of the lineup saga.
 */
export default function* lineupSaga() {
  // PLAYERS
  yield takeLatest(
    lineupActions.players.FETCH,
    fetchPlayers
  )
  yield takeLatest(
    lineupActions.players.UPDATE,
    updatePlayerTable
  )
  yield takeLatest(
    lineupActions.players.REMOVE,
    removePlayerProjections
  )
  // SETTINGS
  yield takeLatest(
    lineupActions.teamStacks.FETCH,
    fetchTeamStacks
  )
  yield takeLatest(
    lineupActions.teamStacks.UPDATE,
    updateTeamStacks
  )
  yield takeLatest(
    lineupActions.teamStacks.REMOVE,
    removeTeamStacks
  )
  // PRESETS
  yield takeLatest(
    lineupActions.presets.FETCH,
    fetchPresets
  )
  // EXPOSURE STATS
  yield takeLatest(
    lineupActions.exposureStats.FETCH,
    fetchExposureStats
  )
  // SLATES & SITES
  yield takeLatest(
    lineupActions.slates.FETCH,
    fetchSlates
  )
  // yield takeLatest(
  //   lineupActions.slates.FETCH_ALL,
  //   fetchAllSlates
  // )
  // DEFAULT SETTINGS
  yield takeLatest(
    lineupActions.settings.FETCH,
    fetchSiteSettings
  )
  yield takeLatest(
    lineupActions.settings.UPDATE,
    updateSiteSettings
  )
  yield takeLatest(
    lineupActions.settings.RESET,
    resetSiteSettings
  )
  // LINEUP STATS
  yield takeLatest(
    lineupActions.lineupStats.FETCH,
    fetchLineupStats
  )
  // SAVE LINEUPS
  yield takeLatest(
    lineupActions.savedLineups.UPDATE,
    saveLineups
  )
  // SAVE LINEUPS
  yield takeLatest(
    lineupActions.savedLineups.FETCH,
    fetchSavedLineups
  )
  yield takeLatest(
    lineupActions.opt.REPLACE_LINEUP,
    replaceLineup
  )
}
