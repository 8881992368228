// const LOOKUP_URI_BASE = 'https://d18d41pmnj8qe1.cloudfront.net/sports/nfl/logo/opt/49ers-logo.svg'
const LOOKUP_URI_BASE = 'https://d18d41pmnj8qe1.cloudfront.net/sports'

const IMG_TAG_CACHE = {}

const SPORT_TO_RENAME = {
  'mlb': {
    'Chicago White Sox': 'whitesox',
    'Boston Red Sox': 'redsox',
    'Toronto Blue Jays': 'bluejays'
  }
}

const SPORT_TO_SANITIZE_NAME = {
  'mlb': (tn) => {
    const tn_parts = tn.split(' ')
    return tn_parts[tn_parts.length - 1]
  },
  'nba': (tn) => {
    const tn_parts = tn.split(' ')
    return tn_parts[tn_parts.length - 1]
  }
}

export const getTeamImg = (sport, teamName) => {
  if (SPORT_TO_RENAME[sport] && SPORT_TO_RENAME[sport][teamName]) {
    // Handle Red Sox and such
    teamName = SPORT_TO_RENAME[sport][teamName]
  } else if (SPORT_TO_SANITIZE_NAME[sport]) {
    teamName = SPORT_TO_SANITIZE_NAME[sport](teamName)
  }

  if (!IMG_TAG_CACHE[teamName]) {
    IMG_TAG_CACHE[teamName] = (
      <img src={`${LOOKUP_URI_BASE}/${sport.toLowerCase()}/logo/opt/${teamName.toLowerCase()}-logo.svg`} style={{width: '28px'}} alt={teamName} />
    )
  }
  
  return IMG_TAG_CACHE[teamName]
}

const LOOKUP_SITE_URI_BASE = 'https://d18d41pmnj8qe1.cloudfront.net/logos'
const SITE_IMG_TAG = {}

export const getSiteImg = (site) => {
  if (!SITE_IMG_TAG[site]) {
    SITE_IMG_TAG[site] = (
      <img src={`${LOOKUP_SITE_URI_BASE}/${site.toLowerCase()}.png`} style={{width: '28px'}} alt={site} />
    )
  }
  
  return SITE_IMG_TAG[site]
}

const SPORT_IMG_TAG = {}

export const getSportImg = (sport) => {
  if (!SPORT_IMG_TAG[sport]) {
    SPORT_IMG_TAG[sport] = (
      <img src={`${LOOKUP_SITE_URI_BASE}/${sport.toLowerCase()}-logo.png`} style={{height: '30px', marginRight: '8px'}} alt={sport} />
    )
  }
  
  return SPORT_IMG_TAG[sport]
}

let ANCHOR_IMG

export const getAnchorImg = () => {
  if (!ANCHOR_IMG) {
    ANCHOR_IMG = (
      <img src={`${LOOKUP_SITE_URI_BASE}/anchor.svg`} style={{width: '28px'}} alt={sport} />
    )
  }
  
  return ANCHOR_IMG
}